import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../App'
import { JobType } from '../../pages/jobs'

const NUM_JOBS_DISPLAYED = 3

export const Jobs = () => {
  const { user } = useContext<any>(UserContext)
  const [jobs, setJobs] = useState<JobType[]>([])

  useEffect(() => {
    if (user)
      fetch(`${process.env.REACT_APP_API_ENDPOINT}/jobs`, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${user.accessToken}`,
        },
      })
        .then(res => res.json())
        .then(data => {
          setJobs(data.splice(0, NUM_JOBS_DISPLAYED))
        })
        .catch(err => console.error(err))
  }, [user])

  return (
    <div className="container flex flex-col items-center justify-center md:w-1/2 w-full mx-auto">
      <div className="w-full px-4 mb-4 text-center items-center bg-white border rounded-md shadow md:px-6 ">
        <h2 className="text-2xl py-2 mb-0 font-bold text-indigo-700">Jobs</h2>
      </div>
      <ul className="flex flex-col w-full">
        {jobs.map(job => (
          <li className="flex flex-row mb-2 border-gray-400">
            <div className="transition duration-500 shadow ease-in-out transform hover:-translate-y-1 hover:shadow-lg hover:bg-violet-100 select-none cursor-pointer bg-white rounded-md flex flex-1 items-center p-4">
              <div className="flex-1 pl-1 md:mr-16">
                <div className="font-medium">{job.company}</div>
                <div className="text-sm text-gray-600">{job.position}</div>
                <div className="text-xs text-gray-600">{job.date_posted}</div>
              </div>
              <button className="flex justify-end w-24 text-right">
                <svg
                  width="12"
                  fill="currentColor"
                  height="12"
                  className="text-gray-500 hover:text-gray-800"
                  viewBox="0 0 1792 1792"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M1363 877l-742 742q-19 19-45 19t-45-19l-166-166q-19-19-19-45t19-45l531-531-531-531q-19-19-19-45t19-45l166-166q19-19 45-19t45 19l742 742q19 19 19 45t-19 45z"></path>
                </svg>
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}
