import React, { useEffect } from 'react'
import { useState } from 'react'

export const Events = () => {
  const [events, setEvents] = useState<any[]>([])

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/events`)
      .then(res => res.json())
      .then(data => {
        setEvents(data)
      })
      .catch(err => console.log(err))
  }, [])

  return (
    <>
      <div className="w-full md:w-1/3 border-b rounded-t overflow-hidden bg-white shadow sm:rounded-md">
        <h1 className="text-indigo-700 font-semibold text-2xl text-center p-3">Events</h1>
        <hr className="h-px bg-gray-200 border-0"></hr>
        <ul className="divide-y divide-gray-200">
          {!events
            ? 'Loading'
            : events.slice(0, 4).map((item, index) => {
                return (
                  <li key={index}>
                    <a href={item.url} className="block hover:bg-gray-50">
                      <div className="p-4 sm:px-6">
                        <div className="flex gap-4 items-center">
                          <div className="relative block">
                            {item && item.photoUrl && (
                              <img
                                src={item.photoUrl}
                                alt="profil"
                                className="mx-auto object-cover h-14 w-24"
                              />
                            )}
                          </div>
                          <div className="flex flex-col">
                            <p className="text-gray-700 text-md md:truncate">{item.event_name}</p>
                            <p className="flex items-center font-light text-gray-500 text-md">
                              {item.date}
                            </p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </li>
                )
              })}
        </ul>
      </div>
    </>
  )
}
