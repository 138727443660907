import { LabType } from './index'

export default function SingleLabs({ Lab }: { Lab: LabType }) {
  return (
    <div className="rounded-lg lg:mx-4 mx-3 lg:w-[30%] md:w-2/5 md:mx-2 w-[80%] justify-center overflow-hidden shadow-lg dark:bg-indigo-950 my-3">
      <a href={`/lab/${Lab.uid}`}>
        <div className="p-5 w-full">
          <p className="mb-12 text-xl font-normal text-center text-white">Week: {Lab.week}</p>
          <h1 className="text-3xl font-bold text-center text-white">{Lab.topic}</h1>
          <p className="mb-12 text-xl font-normal text-center text-white"></p>
          <div className="flex justify-between">
            <p className="mb-12 text-xl font-normal text-center text-white">
              Assignment: {Lab.description}
            </p>
          </div>
        </div>
      </a>
    </div>
  )
}
