import { useState, useEffect, useContext } from 'react'
import { Hero } from '../../components/Hero'
import SingleCodingChallenge from './SingleCodingChallenge'
import { UserContext } from '../../App'

export interface Challenges {
  _id: string
  uid: string
  topic: string
  difficulty_level: string
  title: string
  description: string
  created_by: string
  enabled: true
}

export default function CodingChallenges() {
  const { user } = useContext(UserContext)
  const [codingChallenges, setCodingChallenges] = useState<Challenges[]>([])

  useEffect(() => {
    if (user) {
      fetch(`${process.env.REACT_APP_API_ENDPOINT}/codeChallenges/`, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
          //@ts-ignore
          Authorization: `Bearer ${user.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => setCodingChallenges(data))
        .catch(err => console.log(err))
    }
  }, [user])

  console.log(codingChallenges)

  return (
    <>
      <Hero heading="Code Challenges" subHeading="From 0 to Hero" type="events" />
      <div className="container mx-auto px-4 py-8">
        <div className="flex flex-wrap -mx-4">
          {!codingChallenges ? (
            <div className="spinner-border text-danger" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            codingChallenges?.map(codingChallenge => (
              <div key={codingChallenge._id} className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8">
                <SingleCodingChallenge codingChallenge={codingChallenge} />
              </div>
            ))
          )}
        </div>
      </div>
    </>
  )
}
