import { useState, useEffect, useContext } from 'react'
import Job from './JobItem'
import { UserContext } from '../../App'
import { Hero } from '../../components/Hero'
import './Jobs.scss'

export interface JobType {
  _id: string
  uid: string
  company: string
  position: string
  type: string
  frequency: string
  location: string
  date_posted: string
  url: string
  created_by: string
  enabled: true
}

export default function Jobs() {
  const { user } = useContext<any>(UserContext)
  const [jobs, setJob] = useState<JobType[]>([])

  useEffect(() => {
    if (user)
      fetch(`${process.env.REACT_APP_API_ENDPOINT}/jobs`, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${user.accessToken}`,
        },
      })
        .then(res => res.json())
        .then(setJob)
        .catch(console.error)
  }, [user])

  return (
    <section>
      <Hero heading="Jobs" subHeading="Apply for Jobs and share like never before." type="events" />
      <div className="grid md:grid-cols-3 gap-4 my-10 mx-5 sm:grid-cols-1 p-4">
        {!jobs ? <p>Loading...</p> : jobs.map(job => <Job job={job} key={job._id}></Job>)}
      </div>
    </section>
  )
}
