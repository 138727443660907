import { IAlumni } from './index'
//@ts-expect-error
export default function AlumniCard(props) {
  const alumni: IAlumni = props.alumni
  return (
    <div className="w-64 bg-white shadow-lg rounded-2xl">
      <img alt="" className="w-full mb-4 rounded-t-lg h-28 bg-indigo-600" />
      <div className="flex flex-col items-center justify-center p-4 -mt-28">
        <img
          alt="profil"
          src={`https://bocacode.com/` + alumni.image_full}
          className="mx-auto object-cover rounded-full h-28 w-28 border-2 border-indigo-600"
        />
        <h2 className="mt-2 text-xl font-medium text-zinc-800">{alumni.name}</h2>
        <p className="text-xs text-zinc-400">{alumni.prev_title}</p>
        <div className="flex items-center justify-between w-full gap-4 mt-8">
          <a
            href={alumni.github}
            target="_blank"
            className="py-2 px-4 text-indigo-600 hover:text-indigo-700 w-full transition ease-in duration-200 text-center text-base font-semibold"
            rel="noreferrer"
          >
            See Github &gt;
          </a>
        </div>
      </div>
    </div>
  )
}
