import { Events } from '../components/Dashboard/Events'
import { Greeting } from '../components/Dashboard/Greeting'
import { Jobs } from '../components/Dashboard/Jobs'
import { News } from '../components/Dashboard/News'
import { Tutorial } from '../components/Dashboard/Tutorial'

const Home = () => {
  return (
    <div className="bg-gray-100 w-screen min-h-screen">
      <main className="container mx-auto flex flex-col gap-4 my-8 px-4 sm:px-0 ">
        <section className="flex flex-col gap-4 w-full sm:flex-row">
          <Greeting />
          <Jobs />
        </section>
        <section className="flex flex-col gap-4 w-full sm:flex-row justify-stretch">
          <Events />
          <News />
        </section>
        <section>
          <Tutorial />
        </section>
      </main>
    </div>
  )
}

export default Home
