import { useState, useEffect, useContext } from 'react'
import { Hero } from '../../components/Hero'
import { UserContext } from '../../App'

interface VideoCardProp {
  uid: string
  media_type: string
  title: string
  description: string
  topic: string
  url: string
  created_by: string
  createdAt: string
  updatedAt: string
}

// get Thumbnail from youtube video
function GetYoutubeThumbnail(url: string) {
  const videoId = url.split('v=')[1]
  const ampersandPosition = videoId.indexOf('&')
  if (ampersandPosition !== -1) {
    return (
      'https://img.youtube.com/vi/' + videoId.substring(0, ampersandPosition) + '/sddefault.jpg'
    )
  }
  return 'https://img.youtube.com/vi/' + videoId + '/sddefault.jpg'
}

export default function Tutorials() {
  const { user } = useContext(UserContext)

  const [tutorialVideos, setTutorialVideos] = useState<any[]>([])
  useEffect(() => {
    if (user)
      fetch(`${process.env.REACT_APP_API_ENDPOINT}/tutorials`, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
          //@ts-ignore
          Authorization: `Bearer ${user.accessToken}`,
        },
      })
        .then(res => res.json())
        .then((data: VideoCardProp[]) => {
          if (data && data.length > 0) {
            data = data?.sort((a: VideoCardProp, b: VideoCardProp) => {
              return b.updatedAt > a.updatedAt ? 1 : -1
            })
            setTutorialVideos(data)
          }
        })
        .catch(err => console.error(err))
  }, [user])

  return (
    <>
      <Hero heading="Tutorials" subHeading="Checkout our TUTORIALS BC" type="codeChallenge" />

      <div className="flex flex-row flex-wrap justify-evenly m-auto">
        {!tutorialVideos ? (
          <h1>Loading...</h1>
        ) : (
          <>
            {tutorialVideos.map((tutorial: VideoCardProp, index: Number) => {
              return (
                <div
                  className="lg:mx-4 mx-3 lg:w-[30%] md:w-2/5 md:mx-2 justify-center my-5 rounded hover:scale-105 overflow-hidden shadow-lg cursor-pointer"
                  onClick={e => window.open(tutorial.url)}
                >
                  <img
                    className="w-full"
                    src={GetYoutubeThumbnail(tutorial.url)}
                    alt={tutorial.title + ' video page'}
                  />
                  <div className="px-6 py-4">
                    <div className="font-bold text-grey-800 text-xl mb-2">{tutorial.title}</div>
                    <p className="text-slate-700 text-base">{tutorial.description}</p>
                  </div>
                </div>
              )
            })}
          </>
        )}
      </div>
    </>
  )
}
