import React from 'react'
import { Challenges } from '.'

export interface Props {
  codingChallenge: Challenges
}

export default function SingleCodingChallenge({ codingChallenge }: Props) {
  return (
    <div className="p-5 px-5 content-center bg-red">
      <div className="flex rounded-lg bg-gray-100 p-8 flex-row drop-shadow-md w-full h-80">
        <div className="flex mb-3">
          <h2
            onClick={() => window.open()}
            className="text-indigo-700 text-3xl font-extrabold mb-4 text-center hover:cursor-pointer"
          >
            &nbsp;
          </h2>
        </div>
        <div className="h-full">
          <ul>
            <li className="text-indigo-700 text-3xl font-extrabold mb-4 text-start w-50 h-50">
              {codingChallenge.title}
            </li>
            <li className="flex-grow text-zinc-800 font-bold">
              {codingChallenge.difficulty_level}
            </li>
            <li>{codingChallenge.description}</li>
          </ul>
        </div>
      </div>
    </div>
  )
}
