interface RestaurantType {
  id: string
  photo_url: string
  address: string
  name: string
  rating: number
  menu: string
  menu_url: string
  uid: string
}
export default function SingleRestaurant({ restaurant }: { restaurant: RestaurantType }) {
  const ratingStars = Array.from({ length: restaurant.rating }, (_, index) => (
    <span key={index} role="img">
      ☆
    </span>
  ))

  return (
    <div className="rounded-lg mx-2 w-full lg:w-1/3 max-w-xs flex flex-wrap items-center md:flex-row justify-evenly bg-slate-100 my-5">
      <a href={`/restaurant/${restaurant.uid}`}>
        <img
          src={restaurant.photo_url}
          alt={restaurant.name}
          className="object-cover aspect-square rounded-t-lg w-full object-center mb-8"
        />
      </a>
      <div className="p-5 w-full h-[330px]">
        <h1 className="text-3xl font-bold text-center text-indigo-700">{restaurant.name}</h1>
        <p className="mb-10 text-xl font-normal text-center text-slate-950">
          {restaurant.address || 'Restaurant Address'}
        </p>
        <p className="mb-10 text-xl font-normal text-center text-slate-950">
          Rating: {ratingStars}
        </p>
        <div className="flex justify-center">
          <a
            href={restaurant.menu_url}
            className="flex w-24 px-4 py-3 text-base font-medium text-white bg-rose-600 rounded-lg hover:bg-rose-500 focus:ring-4 focus:outline-none focus:ring-rose-300"
          >
            Menu
            <svg
              aria-hidden="true"
              className="w-6 h-6 ml-3 -mr-1"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </a>
        </div>
      </div>
    </div>
  )
}
