import { useState, useEffect, useContext } from 'react'
import { Hero } from '../../components/Hero'
import { UserContext } from '../../App'

export type NewsArticle = {
  id: string
  createdAt: any
  date: Date
  timestamp: number
  photoURL: string
} & RawNewsArticleData

export type RawNewsArticleData = {
  title: string
  author: string
  body: string
  summary: string
  link: string
}

export default function News() {
  const { user } = useContext(UserContext)
  const [allNews, setAllNews] = useState<NewsArticle[]>([])

  useEffect(() => {
    if (user)
      fetch(`${process.env.REACT_APP_API_ENDPOINT}/news`, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
          // @ts-ignore
          Authorization: `Bearer ${user.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          data = data?.sort((a: NewsArticle, b: NewsArticle) => b.timestamp - a.timestamp)
          setAllNews(data)
        })
        .catch(err => console.log(err))
  }, [user])

  return (
    <>
      <Hero heading="News" subHeading="Happening at Boca Code Now" type="news" />
      <div className="grid md:grid-cols-2 gap-4 my-10 mx-5 sm:grid-cols-1 p-4 ">
        {allNews.map(news => {
          return (
            <div className="grid w-full p-4 ">
              <div className=" bg-gray-100 p-6 rounded-lg">
                <img
                  className="h-40 rounded w-full object-cover object-center mb-6"
                  src={news.photoURL}
                  alt=""
                />
                <h2 className="text-lg text-indigo-700 title-font mb-4 font-semibold">
                  {' '}
                  <a href={news.link}>{news.title}</a>{' '}
                </h2>

                <p className="leading-relaxed text-base">{news.summary}</p>
                <br />

                <h3 className="tracking-widest text-rose-600 text-xs font-medium title-font hover:text-rose-400">
                  <a href={news.link} target="_blank" rel="noreferrer">
                    Learn More
                  </a>
                </h3>
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}
