import { useEffect, useState, useContext } from 'react'
import { Hero } from '../../components/Hero'
import SingleRestaurant from './SingleRestaurant'
import { UserContext } from '../../App'

interface RestaurantType {
  id: string
  photo_url: string
  address: string
  name: string
  rating: any
  menu: string
  menu_url: string
  uid: string
}

export default function Restaurants() {
  const { user } = useContext<any>(UserContext)
  const [data, setData] = useState<RestaurantType[]>([])

  useEffect(() => {
    if (user)
      fetch(`${process.env.REACT_APP_API_ENDPOINT}/restaurants`, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
          //ts-ignore
          Authorization: `Bearer ${user.accessToken}`,
        },
      })
        .then(res => res.json())
        .then(data => setData(data))
  }, [user])

  return (
    <>
      <Hero heading="Restaurants" subHeading="Recommended by Students" type="restaurant" />
      <section className="container mx-auto px-1">
        <div className="flex flex-wrap justify-center">
          {!data ? (
            <div className="flex justify-center items-center h-64">
              <svg
                className="animate-spin h-10 w-10 text-blue-600"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </div>
          ) : (
            data?.map((restaurant, index) => {
              console.log(restaurant)

              return <SingleRestaurant key={restaurant.id + index} restaurant={restaurant} />
            })
          )}
        </div>
      </section>
    </>
  )
}
