import { useEffect, useState } from 'react'

import styles from './Alumni.module.scss'
import Spinner from 'react-bootstrap/Spinner'
import UploadResumeModal from './ResumeUpload'
import AlumniCard from './AlumniCard'
export interface IAlumni {
  _id: string
  github: string
  image_full: string
  name: string
  email: string
  linkedin: string
  uid: string
  user_slug: string
  prev_title: string
  created_by: string
  inspiration: string
  most_important_skill: string
  why_boca_code: string
  project_title: string
  project: string
  project_about: string
  project_image: string
  resume: string
  about: string
  doc_id: string
  enabled: true
  createdAt: string
  updatedAt: string
  __v: 0
}
export default function Alumni() {
  const [allAlumni, setAllAlumni] = useState<IAlumni[]>([])

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/students`)
      .then(res => res.json())
      .then(data => {
        setAllAlumni(data)
      })
      .catch(alert)
  }, [])
  return (
    <>
      <section className="bg-white dark:bg-indigo-600">
        <div className="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16">
          <h1 className="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl dark:text-white">
            Alumni
          </h1>
          <p className="mb-8 text-lg font-normal text-white lg:text-xl sm:px-16 lg:px-48 dark:text-white">
            Our alumni are the heart of our community. They are the ones who have gone through our
            program and are now working in the industry. They are the ones who are always willing to
            help.
          </p>
        </div>
      </section>

      <div className={styles['container']}>
        <UploadResumeModal />
      </div>
      <div className="grid grid-cols-2 gap-4 sm:grid-cols-3 lg:grid-cols-6">
        {!allAlumni ? (
          <Spinner animation="border" role="status" variant="primary" />
        ) : (
          allAlumni.map(alumni => {
            return <AlumniCard alumni={alumni} key={alumni._id} />
          })
        )}
      </div>
    </>
  )
}
