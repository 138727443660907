import React, { useEffect, useState, useContext } from 'react'
import { Hero } from '../../components/Hero'
import SingleLabs from './SingleLabs'
import { UserContext } from '../../App'

export interface LabType {
  id: string
  uid: number
  week: string
  topic: string
  description: string
}

const Labs: React.FC = () => {
  const { user } = useContext(UserContext)
  const [data, setData] = useState<LabType[]>([])

  useEffect(() => {
    if (user)
      fetch(`${process.env.REACT_APP_API_ENDPOINT}/labs`, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
          //@ts-ignore
          Authorization: `Bearer ${user.accessToken}`,
        },
      })
        .then(res => res.json())
        .then(data => setData(data))
  }, [user])

  return (
    <>
      <Hero heading="Labs" subHeading="Cohort Labs" type="news" />
      <section className="container mx-auto px-1">
        <br />
        <div className="flex flex-wrap justify-evenly">
          {!data ? (
            <div className="flex justify-center items-center h-64">
              <svg
                className="animate-spin h-10 w-10 text-blue-600"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </div>
          ) : (
            data?.map((lab, index) => {
              console.log(lab)
              return <SingleLabs key={lab.id + index} Lab={lab} />
            })
          )}
        </div>
      </section>
    </>
  )
}

export default Labs
