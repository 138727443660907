import { useState } from 'react'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { storage } from '../../firebaseConfig'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import styles from './Alumni.module.scss'

function UploadResume(props) {
  const [progress, setProgress] = useState(0)
  const formHandler = e => {
    e.preventDefault()
    const file = e.target[0].files[0]
    uploadFiles(file)
  }

  const uploadFiles = file => {
    if (!file) return
    const storageRef = ref(storage, `/resume/${file.name}`)
    const uploadTask = uploadBytesResumable(storageRef, file)

    uploadTask.on(
      'state_changed',
      snapshot => {
        const prog = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)

        setProgress(prog)
      },
      err => console.log(err),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(url => console.log(url))
      }
    )
  }

  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Upload Resume</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Please name file: lastname_resume_cohort#.pdf </h4>
        <form onSubmit={formHandler} className={styles['resume-form']}>
          <input type="file" />
          <Button type="submit" onClick={props.onHide}>
            {' '}
            Upload
          </Button>
        </form>
      </Modal.Body>
    </Modal>
  )
}

export default function UploadResumeModal() {
  const [modalShow, setModalShow] = useState(false)

  return (
    <>
      {/* <Button
        className={styles['btn-upload-resume']}
        variant="primary"
        onClick={() => setModalShow(true)}
      >
        Upload Resume
      </Button> */}
      <UploadResume show={modalShow} onHide={() => setModalShow(false)} />
    </>
  )
}
