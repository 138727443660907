import * as React from 'react'

export const Hero = ({
  heading,
  subHeading,
  type,
}: {
  heading: string
  subHeading: string
  type: string
}) => {
  //url('/public/img/heros/${type}Hero.jpg\')
  return (
    <div className="relative px-6 py-12 text-left text-neutral-800">
      <div
        style={{ backgroundImage: `url('/img/heros/${type}Hero.jpg')` }}
        className=" opacity-30 back absolute top-0 bottom-0 left-0 right-0 bg-cover bg-no-repeat bg-center "
      />
      <h1 className="mb-6 relative text-4xl font-bold">{heading}</h1>
      <h3 className="mb-8 relative text-3xl font-bold">{subHeading}</h3>
    </div>
  )
}
