export default function Event({ event, isReversed }) {
  // const isReversed = false
  return (
    <div className="flex flex-col md:flex-row p-10">
      <div className={`md:px-8 md:w-1/2 ${isReversed ? 'md:order-2' : ''}`}>
        <img
          src={event.photoUrl}
          alt="Event"
          className="relative object-contain mx-auto rounded shadow-lg mb-10"
        />
      </div>
      <div className={`md:w-1/2 md:px-8 ${isReversed ? 'md:pr-8' : 'md:pl-8'}`}>
        <h2 className="text-2xl font-bold mb-4 ">{event.event_name}</h2>
        <p className="my-10 text-lg leading-7 text-zinc-500">
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Distinctio illum similique odio
          provident accusantium ullam exercitationem dolores accusamus vero animi eveniet facere ex
          corrupti, labore rerum dolore doloremque sed deserunt.≈≈
        </p>
        <p className="mt-3 text-xl leading-7 text-indigo-700 font-bold">Date: {event.date}</p>
        <a href={event.url} className="justify-center">
          <button className="rounded-md bg-rose-600 px-5 py-2.5 mt-5 text-sm font-medium text-zinc-50 transition hover:bg-rose-500 hover:text-zinc-100">
            Register
          </button>
        </a>
      </div>
    </div>
  )
}
