import React from 'react'
import './JobItem.scss'
import { JobType } from './index'
//@ts-expect-error
export default function Job(props) {
  const jobData: JobType = props.job
  return (
    <div className="flex -m-4 justify-center">
      <div className="p-5 px-5 md:w-full">
        <div className="flex rounded-lg h-full w-full bg-gray-100 p-8 flex-col drop-shadow-md">
          <div className="flex items-center mb-3">
            <h2
              onClick={() => window.open(jobData.url)}
              className="text-indigo-700 text-3xl font-extrabold mb-4 text-center hover:cursor-pointer"
            >
              {jobData.company}
            </h2>
          </div>
          <div className="flex-grow text-zinc-800">
            <ul className="leading-relaxed text-base">
              <li className="font-bold">{jobData.position}</li>
              <li>{jobData.type}</li>
              <li>{jobData.location}</li>
              <li>{jobData.frequency}</li>
              <li>{jobData.date_posted}</li>
            </ul>
            <button
              onClick={() => window.open(jobData.url)}
              className="block rounded-lg cursor-pointer bg-rose-600 px-5 py-2.5 mt-5 text-md font-medium text-zinc-50 transition hover:bg-rose-500 hover:text-zinc-100"
            >
              Learn More
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
