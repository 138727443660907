import { useState, useEffect, useContext } from 'react'
import { UserContext } from '../../App'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'

interface VideoCardProp {
  uid: string
  media_type: string
  title: string
  description: string
  topic: string
  url: string
  created_by: string
  createdAt: string
  updatedAt: string
}

// get Thumbnail from youtube video
function GetYoutubeThumbnail(url: string) {
  const videoId = url.split('v=')[1]
  const ampersandPosition = videoId.indexOf('&')
  if (ampersandPosition !== -1) {
    return (
      'https://img.youtube.com/vi/' + videoId.substring(0, ampersandPosition) + '/sddefault.jpg'
    )
  }
  return 'https://img.youtube.com/vi/' + videoId + '/sddefault.jpg'
}

export const Tutorial = () => {
  const { user } = useContext(UserContext)

  const [tutorialVideos, setTutorialVideos] = useState<any[]>([])
  useEffect(() => {
    if (user)
      fetch(`${process.env.REACT_APP_API_ENDPOINT}/tutorials`, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
          //@ts-ignore
          Authorization: `Bearer ${user.accessToken}`,
        },
      })
        .then(res => res.json())
        .then((data: VideoCardProp[]) => {
          if (data && data.length > 0) {
            data = data?.sort((a: VideoCardProp, b: VideoCardProp) => {
              return b.updatedAt > a.updatedAt ? 1 : -1
            })
            setTutorialVideos(data)
          }
        })
        .catch(err => console.error(err))
  }, [user])

  return (
    <>
      <h1 className="mb-6 relative text-4xl font-bold">Tutorials</h1>
      <div>
        {!tutorialVideos ? (
          <h1>Loading...</h1>
        ) : (
          <Slider
            dots
            infinite
            // autoplay
            speed={300}
            slidesToShow={4}
            slidesToScroll={4}
            initialSlide={0}
            responsive={[
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                },
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                },
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
            ]}
          >
            {tutorialVideos.map((tutorial: VideoCardProp) => {
              return (
                <div
                  key={tutorial.url}
                  className="w-[300px] px-2 mr-4 rounded overflow-hidden shadow-lg h-[400px] cursor-pointer"
                >
                  <img
                    className="w-full"
                    src={GetYoutubeThumbnail(tutorial.url)}
                    alt={tutorial.title + ' video page'}
                  />
                  <div className="px-6 py-4" onClick={() => window.open(tutorial.url)}>
                    <div className="font-bold text-xl mb-2">{tutorial.title}</div>
                    <p className="text-gray-700 text-base">{tutorial.description}</p>
                  </div>
                </div>
              )
            })}
          </Slider>
        )}
      </div>
    </>
  )
}
