import { getAuth, signOut } from 'firebase/auth'
import { Link, useNavigate } from 'react-router-dom'
import { useContext } from 'react'
import { UserContext } from '../../App'

export default function Navigation() {
  const { user, setUser } = useContext(UserContext)
  const navigate = useNavigate()

  const auth = getAuth()

  const logOutUser = async () => {
    await signOut(auth)
    //@ts-ignore
    setUser(undefined)
    localStorage.clear()
    navigate('/login')
    console.log('logged out')
  }

  return (
    <>
      <div className="flex md:flex-column lg:items-center  flex-row py-2 left-0 top-0 w-full bg-indigo-950 z-40 w-100 h-20"></div>
      <div className="flex md:flex-column lg:items-center  flex-row fixed py-2 left-0 top-0 w-full bg-indigo-950 z-40 w-100 px-20">
        <Link to={'/'} className="mr-10">
          <img
            src="https://bocacode.com/assets/images/bc-logos/boca-code-rgb-logo-tag.svg"
            alt="Boca Code logo"
            width="150px"
          />
        </Link>

        {user && (
          <>
            <div className="flex flex-1 items-center gap-3  ">
              <Link to="jobs" className="text-white">
                JOBS
              </Link>
              <Link to="news" className="text-white">
                NEWS
              </Link>
              <Link to="events" className="text-white">
                EVENTS
              </Link>
              <Link to="alumni" className="text-white ">
                ALUMNI
              </Link>
              <Link to="tutorials" className="text-white ">
                TUTORIALS
              </Link>
              <Link to="challenges" className="text-white ">
                CHALLENGES
              </Link>
              <Link to="labs" className="text-white ">
                LABS
              </Link>
              <Link to="restaurants" className="text-white ">
                RESTAURANTS
              </Link>

              {/* <StudentsAreaTab /> */}
            </div>
            <button
              className="block rounded-md cursor-pointer bg-rose-600 px-5 py-2.5 text-sm font-medium text-zinc-50 transition hover:bg-zinc-50 hover:text-indigo-950"
              onClick={logOutUser}
            >
              LOGOUT
            </button>
          </>
        )}
      </div>
    </>
  )
}
