import { useEffect, useState, useContext } from 'react'
import { Hero } from '../../components/Hero'
import { UserContext } from '../../App'
import Event from './Event'

export default function Events() {
  const { user } = useContext(UserContext)
  const [events, setEvents] = useState([])

  useEffect(() => {
    if (user)
      // to guarantee we have a user
      fetch(`${process.env.REACT_APP_API_ENDPOINT}/events`, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
          //@ts-ignore
          Authorization: `Bearer ${user.accessToken}`,
        },
      })
        .then(res => res.json())
        .then(setEvents)
  }, [user])

  return (
    <>
      <Hero heading="Events" subHeading="Recommended by Senior Staff" type="events" />

      <section>
        {!events
          ? 'Loading..'
          : events.map((event, index) => {
              const isReversed = index % 2 === 0 // Determine the value of isReversed based on index

              return <Event key={index} event={event} isReversed={isReversed} />
            })}
      </section>
    </>
  )
}
