import React, { useContext } from 'react'
import { UserContext } from '../../App'

export const Greeting = () => {
  const { user } = useContext<any>(UserContext)

  return (
    <div className="flex bg-[url('/public/img/greeting-bk.jpg')] bg-cover w-full h-64 md:h-auto bg-center rounded-md">
      <h2 className="self-end mb-0 text-5xl font-light p-2 text-gray-100 text-shadow">
        Hello {user ? user.first_name : ''}
      </h2>
    </div>
  )
}
