import React, { useState, useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import './styles/index.css'

import Header from './components/layout/Header'
import Home from './pages/Home'
import Restaurants from './pages/restaurants'
import Tutorials from './pages/tutorials'
import Jobs from './pages/jobs'
import Events from './pages/events'
import LabsList from './pages/labs'
import Login from './pages/login'
import Alumni from './pages/alumni'
import CodingChallenges from './pages/challenges'
import News from './pages/news'
export interface IUser {
  id?: string | null
  email?: string | null
  user?: object | null
  type?: string
  createdAt?: string
  setUser?: Function | undefined
  accessToken?: string | null
}

export const UserContext = React.createContext({} as IUser)

function App() {
  const [user, setUser] = useState<IUser>()
  const navigate = useNavigate()

  useEffect(() => {
    const bc_user = localStorage.getItem('bc_user')
    if (bc_user) {
      setUser(JSON.parse(bc_user))
    } else {
      localStorage.clear()
      setUser(undefined)
      navigate('/login')
    }
  }, [])

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {/* {user ? ( */}
      <>
        <Header />
        {!user ? (
          <Login />
        ) : (
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/news" element={<News />} />
            <Route path="/tutorials" element={<Tutorials />} />
            <Route path="/restaurants" element={<Restaurants />} />
            <Route path="/events" element={<Events />} />
            <Route path="/alumni" element={<Alumni />} />
            <Route path="/jobs" element={<Jobs />} />
            <Route path="/labs" element={<LabsList />} />
            <Route path="/challenges" element={<CodingChallenges />} />
          </Routes>
        )}
      </>
      {/* ) : (
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="*" element={<h1> You must but logged in!! </h1>} />
        </Routes>
      )} */}
    </UserContext.Provider>
  )
}

export default App
