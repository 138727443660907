import React, { useEffect } from 'react'
import { useState } from 'react'

export const News = () => {
  const [news, setNews] = useState<any[]>([])

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/news`)
      .then(res => res.json())
      .then(data => {
        setNews(data)
      })
      .catch(err => console.log(err))
  }, [])

  return (
    <>
      <div className="w-full md:w-2/3 border-b rounded-t overflow-hidden bg-white shadow sm:rounded-md">
        <h1 className="text-indigo-700 font-semibold text-2xl text-center p-3">News</h1>
        <hr className="h-px bg-gray-200 border-0"></hr>
        <ul className="divide-y divide-gray-200">
          {!news
            ? 'Loading'
            : news.slice(0, 3).map((item, index) => {
                return (
                  <li key={index}>
                    <div className="block">
                      <div className="flex gap-4 p-4 sm:px-6">
                        <div className="flex gap-4 items-center">
                          <div className="relative block">
                            <img
                              src={item?.photoURL || '/img/favicon.ico'}
                              alt="profil"
                              className="mx-auto object-cover h-14 w-24 "
                            />
                          </div>
                        </div>
                        <p className="leading-relaxed">
                          <p className="text-gray-700 text-md font-semibold md:truncate">
                            {item.title}
                          </p>
                          {item.summary}
                        </p>
                        <div className="mt-2 sm:flex sm:justify-between">
                          <div className="sm:flex">
                            <p className="flex items-center font-light text-gray-500 text-md">
                              {item.timestamps}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <a
                      href={item.link}
                      target="_blank"
                      rel="noreferrer"
                      className="pb-4 text-rose-600 inline-flex items-center px-4 sm:px-6 cursor-pointer hover:scale-105"
                    >
                      Read More
                      <svg
                        className="w-4 h-4 ml-2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M5 12h14"></path>
                        <path d="M12 5l7 7-7 7"></path>
                      </svg>
                    </a>
                  </li>
                )
              })}
        </ul>
      </div>
    </>
  )
}
